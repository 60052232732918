import api from '@frontend/utils/api';

import {
  MutationFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import type { User } from './user';

export type AuthedUser = User;

export async function getAuthedUser() {
  const { data } = await api<AuthedUser>('/auth/user');
  return data;
}

export function useAuthedUser() {
  return useQuery({
    queryFn: getAuthedUser,
    queryKey: ['/auth/user'],
    retry: false,
  });
}

async function signInWithEmail(body: { email: string; password: string }) {
  const { data } = await api<AuthedUser>('/auth/email/signin', {
    method: 'POST',
    body,
  });
  return data;
}

export const signIn = {
  email: signInWithEmail,
} as const;

export function useSignIn<
  M extends keyof typeof signIn,
  FN extends (typeof signIn)[M] = (typeof signIn)[M],
>(method: M) {
  const queryClient = useQueryClient();
  const mutationFn = signIn[method] as MutationFunction<
    ReturnType<FN>,
    Parameters<FN>[0]
  >;

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      queryClient.setQueryData(['/auth/user'], data);
      queryClient.invalidateQueries({ queryKey: ['/auth/user'] });
    },
  });
}

export async function signOut() {
  await api<null>('/auth/signout', { method: 'POST' });
}

export function useSignOut() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: signOut,
    onSuccess: () => {
      queryClient.setQueryData(['/auth/user'], null);
      queryClient.invalidateQueries({ queryKey: ['/auth/user'] });
    },
  });
}

export const signUp = {
  email: async (body: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
  }) => {
    const { data } = await api<AuthedUser>('/auth/email/signup', {
      method: 'POST',
      body,
    });
    return data;
  },
};

export async function inititializeEmailVerification() {
  await api<null>('/auth/email/verify-init', {
    method: 'POST',
  });
}

export async function verifyEmail(body: { token: string }) {
  await api<null>('/auth/email/verify', {
    method: 'POST',
    body,
  });
}

export async function requestMagicLink(body: {
  email: string;
  source:
    | { trigger: 'manual' }
    | { trigger: 'checkout'; reference: string | null };
}) {
  const { data } = await api<{ tokenId: string }>('/auth/magic-link/init', {
    method: 'POST',
    body,
  });
  return data;
}

export async function verifyMagicLink(body: { token: string }) {
  const { data } = await api<{
    state:
      | { trigger: 'manual' }
      | { trigger: 'checkout'; productType: 'book'; productId: string };
    user: AuthedUser;
  }>('/auth/magic-link', {
    method: 'POST',
    body,
  });
  return data;
}

export function useMagicLink() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: verifyMagicLink,
    onSuccess: (data) => {
      queryClient.setQueryData(['/auth/user'], data.user);
      queryClient.invalidateQueries({ queryKey: ['/auth/user'] });
    },
  });
}

export async function initializePasswordReset(body: { email: string }) {
  await api<null>('/auth/email/reset-password-init', {
    method: 'POST',
    body,
  });
}

export async function resetPassword(body: {
  token: string;
  password: string;
  confirmPassword: string;
}) {
  await api<null>('/auth/email/reset-password', {
    method: 'POST',
    body,
  });
}
