import { useCurrentAuth } from '@frontend/contexts/auth-context';
import { usePageviewAnalytics } from '@frontend/utils/analytics';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Topbar } from './Topbar';

export function SiteLayout() {
  usePageviewAnalytics();

  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  return (
    <div className="flex min-h-full flex-col">
      <Topbar isLandingPage={isLandingPage} />

      <main className="shrink-0 grow">
        <Outlet />
      </main>

      <footer className="mx-auto w-full max-w-screen-xl p-8">
        &copy; TechNotes 2023
      </footer>
    </div>
  );
}

export function ProtectedSiteLayout() {
  const auth = useCurrentAuth();

  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  return <SiteLayout />;
}
