import qs from 'qs';
import { useCurrentAuth } from '@frontend/contexts/auth-context';
import { stripeMode } from '@frontend/hooks/useStripeMode';
import api from '@frontend/utils/api';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export type Book = {
  id: number;
  author: string;
  slug: string;
  title: string;
  subtitle: string;
  language: string;
  publishedAt: string | null;
  price: number;
  category: string;
  thumbnail: string | null;
  createdAt: string;
  updatedAt: string;
};

export type BookChapter = {
  bookId: number;
  seq: number;
  title: string;
  contentType: 'md';
  content: string;
};

export async function listBooks({
  page = 1,
  size = 20,
  query,
}: {
  page?: number;
  size?: number;
  query?: string;
}) {
  const { data } = await api<{ items: Book[]; totalItems: number }>(
    `/books?${qs.stringify({ page, size, query })}`,
  );
  return data;
}

export function useBooks(query: {
  page: number;
  size?: number;
  query?: string;
}) {
  return useQuery({
    queryKey: ['/books', query],
    queryFn: () => listBooks(query),
    placeholderData: keepPreviousData,
  });
}

export async function listBooksByCategory(
  category: string,
  { page = 1, size = 20 }: { page?: number; size?: number },
) {
  const { data } = await api<{ items: Book[]; totalItems: number }>(
    `/books?category=${category}&page=${page}&size=${size}`,
  );
  return data;
}

export function useBooksByCategory(
  category: string,
  query: { page: number; size?: number },
) {
  return useQuery({
    queryKey: ['/books?category', category, query],
    queryFn: () => listBooksByCategory(category, query),
    placeholderData: keepPreviousData,
  });
}

export async function listPurchasedBooks(
  userId: '@me' | number,
  { page = 1, size = 20 }: { page?: number; size?: number },
) {
  const { data } = await api<{ items: Book[]; totalItems: number }>(
    `/books?user=${userId}&page=${page}&size=${size}`,
  );
  return data;
}

export function usePurchasedBooks(query: { page: number; size?: number }) {
  return useQuery({
    queryKey: ['/books?user', '@me', query],
    queryFn: () => listPurchasedBooks('@me', query),
    placeholderData: keepPreviousData,
  });
}

export async function createBookCheckoutSession(bookId: number) {
  const { data } = await api<{ session: { id: string } }>(
    `/books/${bookId}/checkout`,
    {
      method: 'POST',
      headers: {
        ...stripeMode.getHeaders(),
      },
    },
  );
  return data;
}

export async function getAllPurchasedBooks(userId: '@me') {
  const { data } = await api<{
    items: Array<{ bookId: number }>;
    totalItems: number;
  }>(`/users/${userId}/books`);

  return data;
}

function usePurchasedBookIds(userId: null | '@me' = '@me') {
  return useQuery({
    queryKey: ['/users/{userId}/books', userId],
    queryFn: () =>
      getAllPurchasedBooks(userId ?? '@me').then(
        ({ items }) => new Set(items.map((item) => item.bookId)),
      ),
    enabled: Boolean(userId),
  });
}

export function useIsPurchasedBook(bookId: number, userId?: '@me') {
  const { status } = useCurrentAuth();

  const { data, isLoading } = usePurchasedBookIds(
    status === 'authed' && bookId > 0 ? userId : null,
  );

  return {
    isLoading: status === 'loading' || isLoading,
    isPurchased: status === 'authed' && Boolean(data?.has(bookId)),
    isUserAuthed: status === 'authed',
  };
}

export async function getBook(bookId: number) {
  const { data } = await api<Book & { overview: BookChapter | null }>(
    `/books/${bookId}`,
  );
  return data;
}

export function useBook(bookId: number = 0) {
  return useQuery({
    queryKey: ['/books/{bookId}', bookId],
    queryFn: () => getBook(bookId),
    enabled: bookId > 0,
  });
}

export async function getAllBookChapters(bookId: number) {
  const { data } = await api<{
    items: Array<BookChapter>;
    totalItems: number;
  }>(`/books/${bookId}/chapters`);

  return data;
}

export function useBookChapters(bookId: number = 0) {
  return useQuery({
    queryKey: ['/books/{bookId}/chapters', bookId],
    queryFn: () => getAllBookChapters(bookId),
    enabled: bookId > 0,
    retry: false,
  });
}
