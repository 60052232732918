import {
  Button,
  ButtonProps,
  Link as NextUILink,
  LinkProps as NextUILinkProps,
} from '@nextui-org/react';
import { forwardRef } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

export const Link = forwardRef(function Link(
  props: Omit<NextUILinkProps & RouterLinkProps, 'href' | 'to'> &
    ({ href: NextUILinkProps['href'] } | { to: RouterLinkProps['to'] }),
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  return <NextUILink as={RouterLink} {...props} ref={ref} />;
});

export function ButtonLink(
  props: ButtonProps &
    ({ href: NextUILinkProps['href'] } | { to: RouterLinkProps['to'] }),
) {
  return <Button as={Link} {...props} />;
}
