import { useAuthedUser } from '@frontend/api/auth';
import { Spinner } from '@nextui-org/react';
import { useMemo, useRef } from 'react';
import { AuthContext } from './context';

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const isInitialLoadDone = useRef(false);

  const user = useAuthedUser();

  const value = useMemo(() => {
    if (user.data) {
      isInitialLoadDone.current = true;
      return {
        status: 'authed' as const,
        user: user.data,
      };
    }

    if (!user.isLoading) {
      isInitialLoadDone.current = true;
      return {
        status: 'unauthed' as const,
        user: null,
      };
    }

    return {
      status: 'loading' as const,
      user: null,
    };
  }, [user.data, user.isLoading]);

  return (
    <AuthContext.Provider value={value}>
      {isInitialLoadDone.current ? (
        children
      ) : (
        <div className="flex h-screen w-full justify-center">
          <Spinner size="lg" />
        </div>
      )}
    </AuthContext.Provider>
  );
}
