import type { FieldValues, UseFormReturn } from 'react-hook-form';

type Response<
  Data extends null | Record<string, unknown>,
  Params extends null | Record<string, string> = Record<string, string>,
  Meta extends Record<string, unknown> = Record<string, unknown>,
> = {
  meta: Meta;
  params: Params;
  data: Data;
};

export class APIError extends Error {
  status: number;
  error: {
    code: number;
    message: string;
    errors: Array<{
      domain?: string;
      reason?: string;
      message: string;
      location?: string;
      locationType?: string;
      extendedHelp?: string;
      sendReport?: string;
    }>;
  };
  constructor(status: APIError['status'], error: APIError['error']) {
    super(error.message);
    this.status = status;
    this.error = error;
  }
}

export async function api<
  Data extends null | Record<string, unknown>,
  Params extends null | Record<string, string> = Record<string, string>,
  Meta extends Record<string, unknown> = Record<string, unknown>,
>(
  endpoint: string,
  {
    body,
    ...options
  }: Omit<RequestInit, 'body'> & {
    body?: string | FormData | Record<string, unknown>;
  } = {},
): Promise<Response<Data, Params, Meta & { status: number }>> {
  const url = `/api${endpoint}`;

  const request: RequestInit = Object.assign(
    {
      credentials: 'include',
      method: 'GET',
    },
    options,
  );
  request.method = request.method!.toUpperCase();
  const headers = new Headers({
    'content-type': 'application/json',
    ...request.headers,
  });

  if (body instanceof FormData) {
    headers.delete('content-type');
    request.body = body;
  } else if (!['string', 'undefined'].includes(typeof body)) {
    request.body = JSON.stringify(body);
  }

  request.headers = headers;

  const res = await fetch(url, request);

  const contentType = res.headers.get('content-type');

  if (!/application\/json/.test(contentType ?? '')) {
    if (res.status !== 204) {
      throw new Error(`Unsupported Content-Type: ${contentType}`);
    }
  }

  const { data, error, params, ...meta } =
    res.status === 204
      ? { data: null, error: undefined, params: undefined }
      : await res.json();

  if (error) {
    throw new APIError(res.status, error);
  }

  return {
    data,
    params,
    meta: {
      ...meta,
      status: res.status,
    },
  };
}

export function handleAPIError<FV extends FieldValues>(
  err: unknown,
  { form }: { form: UseFormReturn<FV> },
) {
  if (!err) {
    return;
  }

  if (err instanceof APIError) {
    const error = err.error;

    for (const { location, message } of error.errors) {
      if (location) {
        form.setError(location as Parameters<typeof form.setError>[0], {
          message,
          type: 'custom',
        });
      }
    }

    if (
      error.message !== error.errors[0]?.message ||
      !error.errors[0]?.location
    ) {
      form.setError('root.message', {
        message: error.message,
        type: 'custom',
      });
    }

    console.error(err);

    return;
  }

  throw err;
}

export function extractErrorMessages(err: unknown) {
  const errors: string[] = [];

  if (!err) {
    return errors;
  }

  if (err instanceof APIError) {
    const error = err.error;

    if (error.message !== error.errors[0]?.message) {
      errors.push(error.message);
    }

    for (const { location, message } of error.errors) {
      errors.push(location ? `${location}: ${message}` : message);
    }
  } else if (err instanceof Error) {
    errors.push(err.message);
  } else {
    errors.push(`Something went wrong!`);
  }

  console.error(err);

  return errors;
}

export default api;
