import { useBooksByCategory } from '@frontend/api/book';
import { Category } from '@frontend/api/category';
import { Pagination, Skeleton, Spinner } from '@nextui-org/react';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { BookCard, BookCardSkeletons } from './BookCard';
import { ButtonLink } from './Link';

export function CategoryBooksSkeleton() {
  return (
    <div>
      <div className="border-b-1 mb-8 flex w-full items-center border-b-gray-300">
        <Skeleton className="inline-block h-11 w-40 rounded-t-lg border-b-2 border-b-gray-900 pb-2 text-lg md:text-xl lg:text-2xl" />

        <Skeleton className="ml-auto h-10 w-10 rounded-lg bg-gray-200" />
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        <BookCardSkeletons length={4} />
      </div>
    </div>
  );
}

export function CategoryBooks({
  category,
  isOpen,
}: {
  category: Category;
  isOpen?: boolean;
}) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);

  const books = useBooksByCategory(category.title, { page, size });

  const totalPages = useMemo(() => {
    return books.data?.totalItems ? Math.ceil(books.data.totalItems / size) : 0;
  }, [books.data?.totalItems, size]);

  const onPageChange = useCallback((page: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPage(page);
  }, []);

  useEffect(() => {
    setPage(1);
    setSize(isOpen ? 20 : 8);
  }, [isOpen]);

  return (
    <section id={category.slug} className="mb-8 flex w-full flex-col ">
      <div className="border-b-1 mb-8 flex w-full items-center border-b-gray-300">
        <h2 className="inline-block border-b-2 border-b-gray-900 pb-2 text-lg md:text-xl lg:text-2xl">
          {category.title}
        </h2>

        {books.isFetching && <Spinner className="mx-4" size="sm" />}

        <ButtonLink
          aria-label={`${isOpen ? 'Close' : 'Open'} Category: ${
            category.title
          }`}
          className="ml-auto"
          isIconOnly
          to={isOpen ? `/#${category.slug}` : `/categories/${category.slug}`}
          variant="light"
        >
          <HiOutlineChevronRight
            className={clsx('h-5 w-5', isOpen && 'rotate-180')}
          />
        </ButtonLink>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        {books.isLoading ? (
          <BookCardSkeletons length={isOpen ? 8 : 4} />
        ) : (
          books.data?.items.map((book) => <BookCard key={book.id} {...book} />)
        )}
      </div>

      {isOpen && (
        <div className="mt-6 self-center">
          <Pagination
            size="lg"
            onChange={onPageChange}
            page={page}
            total={totalPages}
          />
        </div>
      )}
    </section>
  );
}
