import { useSignOut } from '@frontend/api/auth';
import { useCategories } from '@frontend/api/category';
import { ButtonLink, Link } from '@frontend/components/Link';
import { StripeModeSwitcher } from '@frontend/components/StripeModeSwitcher';
import { useCurrentAuth } from '@frontend/contexts/auth-context';
import { useBoolean } from '@frontend/hooks/use-boolean';
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
} from '@nextui-org/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  HiOutlineInformationCircle,
  HiOutlineLogin,
  HiOutlineSearch,
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';

const HIDE_LOGIN = true;

function SearchInput({
  className,
  onSubmit,
}: {
  className?: string;
  onSubmit?: () => void;
}) {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  return (
    <Input
      type="text"
      placeholder="Search..."
      className={clsx('transition-all', className)}
      endContent={
        <HiOutlineSearch className="text-default-400 pointer-events-none shrink-0" />
      }
      value={query}
      onValueChange={(value) => setQuery(value)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          if (query.trim()) {
            e.currentTarget.blur();
            setQuery('');
            onSubmit?.();
            navigate(`/search?query=${query.trim()}`);
          }
        }
      }}
    />
  );
}

export function Topbar({ isLandingPage }: { isLandingPage?: boolean }) {
  const { user } = useCurrentAuth();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const location = useLocation();

  const { data: categories } = useCategories();

  const [hasScrolledTopbar, setHasScrolledTopbar] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useBoolean(false);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        document
          .querySelector(`${location.hash}`)
          ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }
  }, [location.hash]);

  return (
    <Navbar
      isBordered
      maxWidth="full"
      onMenuOpenChange={(isOpen) => setIsMenuOpen(isOpen)}
      isMenuOpen={isMenuOpen}
      onScrollPositionChange={(position) => {
        setHasScrolledTopbar(position > 64);
      }}
    >
      <NavbarContent
        as="div"
        justify="start"
        className={clsx((hasScrolledTopbar || !isLandingPage) && 'md:hidden')}
      >
        <NavbarMenuToggle className="md:hidden" />
      </NavbarContent>

      <NavbarMenu>
        <NavbarMenuItem>
          <SearchInput
            className="w-full"
            onSubmit={() => setIsMenuOpen(false)}
          />
        </NavbarMenuItem>
        {categories?.items.map((category) => (
          <NavbarMenuItem key={category.slug}>
            <Button
              variant="light"
              onClick={() => {
                setIsMenuOpen(false);
                if (isLandingPage) {
                  setTimeout(() => {
                    document
                      .querySelector(`#${category.slug}`)
                      ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  });
                } else {
                  navigate(`/categories/${category.slug}`);
                }
              }}
            >
              {category.title}
            </Button>
          </NavbarMenuItem>
        ))}
        {user && (
          <NavbarMenuItem>
            <ButtonLink variant="light" to="/library">
              My Library
            </ButtonLink>
          </NavbarMenuItem>
        )}
      </NavbarMenu>

      <NavbarContent
        as="div"
        justify="start"
        className={clsx('!grow-0 md:!grow', {
          'justify-center': !hasScrolledTopbar && isLandingPage,
          'md:!grow-0': !hasScrolledTopbar && isLandingPage,
        })}
      >
        <NavbarBrand>
          <Link to="/" className="text-black">
            <h1 className="text-md font-bold text-inherit md:text-lg">
              TechNotes
            </h1>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        as="div"
        justify="center"
        className={clsx('hidden md:flex', {
          'fixed left-0 top-16 mx-auto w-full':
            isLandingPage && !hasScrolledTopbar,
        })}
      >
        {categories?.items.map((category, idx) => (
          <Button
            key={category.slug}
            variant="light"
            size="lg"
            className={clsx({
              'max-2xl:hidden': idx > 4,
              'max-xl:hidden': idx > 2,
              'max-lg:hidden': idx > 1,
            })}
            onClick={() => {
              if (isLandingPage) {
                document
                  .querySelector(`#${category.slug}`)
                  ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              } else {
                navigate(`/categories/${category.slug}`);
              }
            }}
          >
            {category.title}
          </Button>
        ))}
        {user && (
          <ButtonLink variant="light" size="lg" to="/library">
            My Library
          </ButtonLink>
        )}
      </NavbarContent>

      <NavbarContent as="div" justify="end">
        <SearchInput className="hidden w-28 focus-within:w-48 md:flex" />

        <StripeModeSwitcher />

        {user ? (
          <Dropdown placement="bottom-end">
            <DropdownTrigger>
              <Avatar
                as="button"
                className="transition-transform"
                color="secondary"
                isBordered
                name={`${user.firstName} ${user.lastName}`}
                getInitials={(name) =>
                  name
                    .split(/\s+/)
                    .map((part) => part[0] ?? '')
                    .join('')
                }
                size="sm"
              />
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Profile Actions"
              variant="flat"
              selectionMode="none"
              onAction={async (key) => {
                if (key === 'profile') {
                  navigate('/app');
                } else if (key === 'signout') {
                  await signOut.mutateAsync();
                }
              }}
            >
              <DropdownItem key="profile" className={clsx({ hidden: !user })}>
                {user ? (
                  <div>
                    <p className="font-semibold">
                      {user.firstName} {user.lastName}
                    </p>
                    {user.email ? (
                      <p>{user.email}</p>
                    ) : (
                      <p>
                        <span>{user.emailTrx} </span>
                        <HiOutlineInformationCircle className="inline-block text-yellow-500" />
                      </p>
                    )}
                  </div>
                ) : null}
              </DropdownItem>
              <DropdownItem key="signout" color="danger">
                Sign Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : HIDE_LOGIN ? null : (
          <Button
            aria-label="SignIn"
            isIconOnly
            onClick={() => {
              navigate('/signin');
            }}
            variant="light"
          >
            <HiOutlineLogin className="h-5 w-5 rotate-180" />
          </Button>
        )}
      </NavbarContent>
    </Navbar>
  );
}
