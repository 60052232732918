import { useCategories } from '@frontend/api/category';
import {
  CategoryBooks,
  CategoryBooksSkeleton,
} from '@frontend/components/CategoryBooks';
import { Button } from '@nextui-org/react';
import { HiOutlineBookOpen } from 'react-icons/hi';

export function HomePage() {
  const categories = useCategories();

  return (
    <div className="flex flex-col">
      <section className="flex shrink-0 flex-col justify-center bg-white py-16 md:pt-32">
        <div className="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-12 lg:py-16">
          <p className="mb-16 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
            Book summaries for technology professionals.
          </p>
          <div className="mb-8 flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-x-4 sm:space-y-0 lg:mb-16">
            <Button
              color="primary"
              size="lg"
              startContent={<HiOutlineBookOpen className="h-5 w-5" />}
              isLoading={categories.isLoading}
              onClick={() => {
                document.querySelector(`#categories`)?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
            >
              Start Reading
            </Button>
          </div>
        </div>
      </section>

      <section
        id="categories"
        className="mx-auto w-full max-w-screen-xl p-8 pt-20 lg:px-12 lg:pb-16"
      >
        {categories.isLoading ? (
          <CategoryBooksSkeleton />
        ) : (
          categories.data?.items.map((category) => (
            <CategoryBooks key={category.id} category={category} />
          ))
        )}
      </section>
    </div>
  );
}
