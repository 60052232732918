import { AuthedUser } from '@frontend/api/auth';
import { createContext, useContext } from 'react';

export const AuthContext = createContext<
  | { status: 'loading' | 'unauthed'; user: null }
  | { status: 'authed'; user: AuthedUser }
>({
  status: 'loading',
  user: null,
});

export function useCurrentAuth() {
  return useContext(AuthContext);
}

export function useCurrentUser() {
  const { user } = useContext(AuthContext);
  if (!user) {
    throw new Error(
      'useCurrentUser should be called inside authenticated context',
    );
  }
  return user;
}
