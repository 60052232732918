import { useCallback, useState } from 'react';

export function useBoolean(initialValue = false) {
  const [value, setValue] = useState(initialValue);

  const toggleValue = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      return setValue(value);
    }

    return setValue((value) => !value);
  }, []);

  return [value, toggleValue] as const;
}
