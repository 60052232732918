import { useCurrentAuth } from '@frontend/contexts/auth-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

const modeSchema = z.enum(['live', 'test']).nullable();

const key = 'x-stripe-mode';

const get = () => {
  const mode = modeSchema.safeParse(window.localStorage.getItem(key) ?? null);
  return mode.success ? mode.data : null;
};

const next = (mode: z.infer<typeof modeSchema>) => {
  switch (mode) {
    case 'live':
      return 'test';
    case 'test':
      return null;
    case null:
      return 'live';
    default:
      return null;
  }
};

const set = (mode: z.infer<typeof modeSchema>) => {
  if (mode) {
    window.localStorage.setItem(key, mode);
  } else {
    window.localStorage.removeItem(key);
  }
  return mode;
};

export const stripeMode = {
  get,
  getHeaders: () => {
    const mode = get();
    return mode ? { [key]: mode } : null;
  },
};

export function useStripeMode() {
  const { user } = useCurrentAuth();

  const [mode, setMode] = useState(() => stripeMode.get());

  useEffect(() => {
    if (user?.role !== 'admin') {
      setMode(set(null));
    }
  }, [user?.role]);

  const cycleMode = useCallback(() => {
    setMode((mode) => set(next(mode)));
    window.location.reload();
  }, []);

  return useMemo(() => {
    return [{ current: mode, next: next(mode) }, cycleMode] as const;
  }, [cycleMode, mode]);
}
