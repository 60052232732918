import { NextUIProvider } from '@nextui-org/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { queryClient } from './api';
import { AuthProvider } from './contexts/auth-context';
import ErrorPage from './ErrorPage.tsx';
import './index.css';
import { HomePage } from './pages/Index.tsx';
import { ProtectedSiteLayout, SiteLayout } from './components/Layout.tsx';
import { initializeAnalytics } from './utils/analytics.ts';

const router = createBrowserRouter([
  {
    path: '/',
    Component: SiteLayout,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        Component: HomePage,
      },
      {
        path: '/books/:bookId',
        async lazy() {
          const { BookOverviewPage } = await import(
            './pages/books/Overview.tsx'
          );
          return { Component: BookOverviewPage };
        },
      },
      {
        path: '/books/:bookId/read:free',
        async lazy() {
          const { BookChaptersPage } = await import(
            './pages/books/BookChapters.tsx'
          );
          return { Component: BookChaptersPage };
        },
      },
      {
        path: '/categories/:categorySlug',
        async lazy() {
          const { ListCategoryBooksPage } = await import(
            './pages/categories/ListBooks.tsx'
          );
          return { Component: ListCategoryBooksPage };
        },
      },
      {
        path: '/search',
        async lazy() {
          const { SearchPage } = await import('./pages/search/Index.tsx');
          return { Component: SearchPage };
        },
      },
    ],
  },
  {
    Component: ProtectedSiteLayout,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/books/:bookId/read',
        async lazy() {
          const { BookChaptersPage } = await import(
            './pages/books/BookChapters.tsx'
          );
          return { Component: BookChaptersPage };
        },
      },
      {
        path: '/library',
        async lazy() {
          const { LibraryPage } = await import('./pages/library/index.ts');
          return { Component: LibraryPage };
        },
      },
    ],
  },
  {
    path: '/signin',
    async lazy() {
      const { SignInPage } = await import('./pages/SignIn.tsx');
      return { Component: SignInPage };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/signup',
    async lazy() {
      const { SignUpPage } = await import('./pages/SignUp.tsx');
      return { Component: SignUpPage };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth/email/verify',
    async lazy() {
      const { EmailVerifyPage } = await import('./pages/auth/EmailVerify.tsx');
      return { Component: EmailVerifyPage };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth/email/password',
    async lazy() {
      const { ResetPasswordPage } = await import(
        './pages/auth/ResetPassword.tsx'
      );
      return { Component: ResetPasswordPage };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/auth/magic-link',
    async lazy() {
      const { MagicLinkPage } = await import('./pages/auth/MagicLink.tsx');
      return { Component: MagicLinkPage };
    },
    errorElement: <ErrorPage />,
  },
  {
    path: '/stripe',
    async lazy() {
      const { StripePage } = await import('./pages/stripe/index.ts');
      return { Component: StripePage };
    },
    children: [
      {
        path: '/stripe/checkout',
        async lazy() {
          const { StripeCheckoutPage } = await import(
            './pages/stripe/index.ts'
          );
          return { Component: StripeCheckoutPage };
        },
      },
      {
        path: '/stripe/checkout/session',
        async lazy() {
          const { StripeCheckoutSessionPage } = await import(
            './pages/stripe/index.ts'
          );
          return { Component: StripeCheckoutSessionPage };
        },
      },
    ],
  },
  {
    path: '/app',
    async lazy() {
      const { AppPage } = await import('./pages/app/index.ts');
      return { Component: AppPage };
    },
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Navigate to="/app/settings" />,
      },
      {
        path: '/app/settings',
        async lazy() {
          const { SettingsPage } = await import('./pages/app/index.ts');
          return { Component: SettingsPage };
        },
      },
      {
        path: '/app/books/:bookId',
        async lazy() {
          const { BookOverviewPage } = await import(
            './pages/books/Overview.tsx'
          );
          return { Component: BookOverviewPage };
        },
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <NextUIProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </QueryClientProvider>
    </NextUIProvider>
  </React.StrictMode>,
);

initializeAnalytics();
