import { useCurrentAuth } from '@frontend/contexts/auth-context';
import { useStripeMode } from '@frontend/hooks/useStripeMode';
import { Button, Tooltip } from '@nextui-org/react';
import clsx from 'clsx';
import { FaStripe } from 'react-icons/fa';

export function StripeModeSwitcher() {
  const [mode, cycleMode] = useStripeMode();

  const { user } = useCurrentAuth();

  if (user?.role !== 'admin') {
    return null;
  }

  return (
    <Tooltip
      content={
        mode.current
          ? `Stripe Mode: ${mode.current.toUpperCase()}`
          : 'Change Stripe Mode'
      }
    >
      <Button
        aria-label={`${mode.next ? 'Change' : 'Clear'} Stripe Mode${
          mode.current ? ` from ${mode.current.toUpperCase()}` : ''
        }${mode.next ? ` to ${mode.next.toUpperCase()}` : ''}`}
        className={clsx({
          'text-purple-500': mode.current === 'live',
          'text-yellow-500': mode.current === 'test',
          'text-gray-300': !mode.current,
        })}
        isIconOnly
        onClick={cycleMode}
        size="sm"
        variant="light"
      >
        <FaStripe className="h-full w-full" />
      </Button>
    </Tooltip>
  );
}
