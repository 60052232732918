import { type Book } from '@frontend/api/book';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  Skeleton,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { Link } from './Link';

function BookCardSkeleton() {
  return (
    <Card>
      <CardBody>
        <Skeleton className="mb-2 h-6 w-4/5 rounded-lg bg-gray-400" />
        <Skeleton className="h-5 w-2/5 rounded-lg bg-gray-200" />
      </CardBody>
      <CardFooter className="flex">
        <Skeleton className="ml-auto h-8 w-16 rounded-lg bg-blue-400" />
      </CardFooter>
    </Card>
  );
}

export function BookCardSkeletons({ length }: { length: number }) {
  return (
    <>
      {Array(length)
        .fill(null)
        .map((_, i) => (
          <BookCardSkeleton key={i} />
        ))}
    </>
  );
}

export function BookCard({
  id,
  title,
  author,
  subtitle,
  isPurchased,
  thumbnail,
}: Book & { isPurchased?: boolean }) {
  const navigate = useNavigate();

  const link = `/books/${id}${isPurchased ? '/read' : ''}`;

  return (
    <Card
      aria-label={`${isPurchased ? 'Read' : 'Open'}: ${title}`}
      className="group min-h-[200px]"
      isPressable
      onClick={() => {
        setTimeout(() => {
          navigate(link);
        }, 200);
      }}
    >
      <Image
        className="z-0 h-full w-full object-cover"
        removeWrapper
        src={`/api/books/thumbnail?key=${thumbnail ?? `null:${id}`}`}
      />
      <CardHeader className="absolute bottom-0 z-10 flex-col rounded-t-none bg-white bg-opacity-90 group-hover:bg-opacity-95">
        <Link className="text-black" to={link}>
          <h3 className="font-bold">{title}</h3>
        </Link>
        <p className="text-small font-bold text-gray-600">{author}</p>
        <p className="text-small mt-2">{subtitle}</p>
      </CardHeader>
    </Card>
  );
}
