import api from '@frontend/utils/api';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export type Category = {
  id: number;
  slug: string;
  title: string;
  createdAt: string;
  updatedAt: string;
};

export async function getAllCategories() {
  const { data } = await api<{
    items: Array<Category>;
    totalItems: number;
  }>(`/categories`);

  return data;
}

export function useCategories() {
  return useQuery({
    queryKey: ['/categories'],
    queryFn: () => getAllCategories(),
  });
}

export function useCategory(slug: string | null) {
  const categories = useCategories();

  const category = useMemo(() => {
    return categories.data?.items.find((item) => item.slug === slug) ?? null;
  }, [categories.data?.items, slug]);

  return {
    data: category,
    isLoading: categories.isLoading,
  };
}
