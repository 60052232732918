import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

const isProduction = import.meta.env.MODE === 'production';
const analyticsId: string = import.meta.env.APP_GOOGLE_ANALYTICS_ID;

let analyticsIntialized = false;

export function initializeAnalytics() {
  if (analyticsIntialized) {
    throw new Error('Analytics already initialized!');
  }

  analyticsIntialized = true;

  if (!isProduction) {
    window.ga = (...params) => {
      console.debug('Analytics:', ...params);
    };
  }

  ReactGA.initialize(analyticsId, {
    gaOptions: {},
    standardImplementation: !isProduction,
  });
}

let previousPage: string | null = null;
export function usePageviewAnalytics() {
  const page = useLocation().pathname;

  useEffect(() => {
    if (previousPage !== page) {
      previousPage = page;
      ReactGA.set({ page });
      ReactGA.pageview(page);
    }
  }, [page]);
}

export function trackEventAnalytics(event: ReactGA.EventArgs) {
  ReactGA.event(event);
}
